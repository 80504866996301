import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`Progress Bar component is a visual indicator that displays the progression of a task or process. It provides users with a clear visual representation of the completion status, making it easier to track and understand ongoing activities.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/progressbar/guideline-progress-bar-anatomy.svg",
      "alt": "anatomy navbar"
    }}></img>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Progress Bar Track: The background element that represents the entire process.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Progress Bar Fill: The filled portion of the progress bar that grows as the task progresses.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Percentage Indicator (Optional): A numerical representation of the completion percentage displayed within or near the progress bar.`}</p>
      </li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`The Progress Bar component is used to visually communicate the progress of a task or process to users. Common use cases include:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`File Uploads: Display the progress of file uploads to indicate how much of the file has been transmitted.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Form Submissions: Show the progress of a form submission to inform users that their request is being processed.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Loading Indicators: Indicate the loading progress of a web page or application feature.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Survey Progress: Display the completion status of surveys, questionnaires, or multi-step processes.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Common places buttons appear`}</strong>{`: Mobile app homepage , website homepage and other pages when needed etc.`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li>
        Real-time Feedback is Essential: You need to provide users with immediate feedback on the progress of a task or
        process.
      </li>
      <li>
        Complex Tasks: Tasks that may take some time to complete or involve multiple steps can benefit from a progress
        bar.
      </li>
      <li>
        Transparency is Required: Users need to understand the status of a process, such as file uploads, downloads, or
        data processing.
      </li>
      <li>
        Enhanced User Experience: A progress bar can improve user satisfaction by reducing uncertainty and frustration.
      </li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li>
        Tasks are Instantaneous: For tasks that complete almost instantly, such as clicking a button with no noticeable
        delay, a progress bar is unnecessary.
      </li>
      <li>
        Irrelevant to User Experience: Displaying progress may not add value if it doesn't significantly impact the
        user's interaction or understanding.
      </li>
      <li>
        Space Constraints: In situations where screen space is limited, consider using a smaller or more discreet
        loading indicator.
      </li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`Ensure that the Progress Bar component is accessible to all users by following these guidelines:`}</p>
    <ul>
      <li parentName="ul">{`Provide alternative text for screen readers to describe the progress.`}</li>
      <li parentName="ul">{`Ensure keyboard navigation is supported, allowing users to interact with the progress bar using keyboard controls.`}</li>
      <li parentName="ul">{`Maintain adequate color contrast between the progress bar fill and background for users with visual impairments.`}</li>
      <li parentName="ul">{`Test with screen readers and assistive technologies to verify accessibility.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="Use clear and descriptive labels if including a label or percentage indicator." aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/progressbar/a11y-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Didn’t provide an indicator progress for the progress bar" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/progressbar/a11y-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When creating Progress Bar :`}</p>
    <ul>
      <li parentName="ul">{`Label: If a label is included, make it concise and informative.`}</li>
      <li parentName="ul">{`Percentage Indicator: If displaying a percentage, use clear formatting and ensure it updates in real-time.`}</li>
      <li parentName="ul">{`Alternative Text: For accessibility, provide alternative text that conveys the progress’s status.`}</li>
      <li parentName="ul">{`Color Coding: If using color to distinguish between completed and remaining progress, choose accessible colors.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="Use color to distinguish between completed and remaining progress, choose colors that are meaningful and intuitive." aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/progressbar/content-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Didn’t use the correct color to distinguish between completed and remaining progress, choose colors that are meaningful and intuitive." aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/progressbar/content-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      